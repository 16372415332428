import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import i18n from './local';
import store from './store';
import api from './libs/api';
import 'less/index.less';
import 'swiper/css/swiper.css'
import 'video.js/dist/video-js.css'
import 'highlight.js/styles/github.css'
import "github-markdown-css/github-markdown.css"
import VueClipboard from 'vue-clipboard2'
import '@cym/base_lib/dist/index.css'

Vue.config.productionTip = false

Vue.use(ViewUI);
Vue.use(VueClipboard)

Vue.prototype.$api = api;

Vue.directive('allow', {
    inserted: (el, binding) => {
        let permissionList = store.state.rolesList,
            values = binding.value.split(','),
            len = values.length,
            removable = false
        if (permissionList.length) {
            for (let i = 0; i < len; i++) {
                if (permissionList.indexOf(values[i]) === -1) {
                    removable = true
                } else {
                    removable = false
                    break
                }
            }
            if (!removable) return
            if (el.parentNode) el.parentNode.removeChild(el)
        }
    }
})

store.dispatch('GET_CONFIG').then(() => {
    api.getWebsite().then(() => {
        new Vue({
            i18n,
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
})
