<template>
     <Modal 
        class="wrap"
        v-model="modal"
        :mask-closable="false"
        :closable="false"
        ok-text=""
        cancel-text=""
        width="360"
    >
        <p slot="header" style="color:#f60;text-align:center">
            <Icon type="ios-information-circle"></Icon>
            <span>{{ title }}</span>
        </p>
        <p style="text-align:center">{{ content }}</p>
        <div slot="footer">
            <Button type="default" v-if="showCancel" @click="cancel"> {{ cancelTxt }}</Button>
            <Button type="error" :loading="loading" @click="confirm">{{ confirmTxt }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: [],
        data () {
            const vm = window.vm;
            return {
                modal: false,
                vm: vm,
                content: '',
                cancelTxt: '',
                showCancel: false,
                loading: false,
                onOk: ()=>{},
                onCancel: ()=>{}
            }
        },
        computed: {},
        methods: {
            remove() {
                this.modal = false;
                this.$destroy();
                document.body.removeChild(this.$el);
            },
            cancel() {
                this.onCancel()
                this.remove()
            },
            async confirm() {
                this.loading = true
                await this.onOk()
                this.remove()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@deep : ~'>>>';

.wrap @{deep}  .ivu-modal-mask, .wrap @{deep} .ivu-modal-wrap {
    z-index: 9999 !important;
}
</style>