

import Vue from 'vue'
import tip from './err.vue'

const TipModal = Vue.extend(tip)

let Tip = function(data) {
    let instance = new TipModal({
        data
    }).$mount().$el
    document.body.appendChild(instance)
}

export { Tip }
