import i18n from '../local'
import SparkMD5 from 'spark-md5'

const utils={
    changeTitle:function(l,a){
        let lo=l.split('.');
        let lang=i18n.messages[a];
        for(let i in lo){
            lang=lang[lo[i]]
        }
        window.document.title=lang;
    },
    changeLang:function(vm, model){
        let lang = ''
        switch(model) {
            case 'cn': lang = 'cn';break;
            case 'en': lang = 'en';break;
            case 'ja': lang = 'ja';break;
            case 'tw': lang = 'tw';break;
        }
        localStorage.setItem('lang',lang);
        vm.$i18n.locale=lang;

    },
    randomWord: function (L){
        let s= '';
        let randomchar=function(){
            let n= Math.floor(Math.random()*62);
            if(n<10) return n; //1-10
            if(n<36) return String.fromCharCode(n+55); //A-Z
            return String.fromCharCode(n+61); //a-z
        }
        while(s.length< L) s+= randomchar();
        return s;
    },
    handleTime: function(timeStamp) {
        if (!timeStamp) return ''
        var lang = localStorage.getItem('lang') || 'zh'
        var t = new Date(timeStamp.replace(/-/g, '/')) //为兼容safari不支持-格式
        var y = t.getFullYear()
        var m = t.getMonth()
        var d = t.getDate()
        var h = t.getHours()
        var mi = t.getMinutes()
        var s = t.getSeconds()
        var newTimeStamp = Date.UTC(y,m,d,h,mi,s)
        var dateStr = new Date(newTimeStamp).toLocaleDateString(lang, { hour12: false })
        var timeStr = new Date(newTimeStamp).toLocaleTimeString(lang, { hour12: false })
        return `${dateStr} ${timeStr}`
    },
    calculateMd5(file, callback) {
        //获取文件内容的MD5值
        // return new Promise((resolve)=>{
        // })
        var blobSlice = File.prototype.slice || File.prototype.webkitSlice || File.prototype.mozSlice,
            chunkSize = 1024 * 1024 * 2,
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5.ArrayBuffer(),
            fileReader = new FileReader();

        fileReader.onload = function(e) {

            spark.append(e.target.result)
            currentChunk++;

            var percentage = currentChunk / chunks * 100

            if(currentChunk < chunks) {
              loadNext()
            }
            callback(percentage)
        }

        function loadNext() {
            var start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= file.size?file.size:(start + chunkSize));
            fileReader.readAsArrayBuffer(blobSlice.apply(file, [start, end]))
        }

        loadNext()
    },
    handleDownload(url, name) {
        var blobURL = url
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', name);

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    },
    deepClone(obj) {
        if (obj === null) return null//null为object，需特殊处理
        let newObj = obj.constructor === Array ? []:{};
        if(typeof obj !== 'object'){
            return
        }else{
            for(let i in obj){
                if(Object.prototype.hasOwnProperty.call(obj, i)){
                    newObj[i] = typeof obj[i] === 'object'?this.deepClone(obj[i]):obj[i];
                }
            }
        }
        return newObj
    },
    handleCountdown(target) {
        let nowtime = new Date(),
            endtime = new Date(target),
            lefttime = endtime.getTime() - nowtime.getTime(),
            leftd = parseInt(lefttime / (1000 * 60 * 60 * 24)),
            lefth = parseInt(lefttime / (1000 * 60  * 60) % 24),
            leftm = parseInt(lefttime / (1000 * 60) % 60),
            lefts = parseInt(lefttime / 1000 % 60);
        return {
            d: leftd,
            h: lefth,
            m: leftm,
            s: lefts
        }
    }
};
export default utils;

