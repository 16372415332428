export const exCN={
    message: {
        success: '成功！',
        fail: '失败!',
        select: '请选择',
        errTip: '错误提示'
    },
    content: {
        
    },
    common: {
        cancel: '取消',
        confirm: '确定',
        total: '总数',
        input: '请输入',
        select: '请选择'
    },
    layout:{
        sysName: '小飞秀',
        logOut: '退出登录',
    },
    navigation: {
        spaces: '媒体素材',
        personSpaces: '个人空间',
        companySpaces: '企业空间',
        templateSpaces: '精选模板',
        templateDesign: '模板设计',
        screens: '数媒营销',
        programs: '节目',
        programEdit: '节目编辑',
        mediaScreens: '数媒屏',
        smartTags: '价签屏',
        personalCenter: '个人中心',
        personalSettings: '个人设置',
        organization: '企业中心',
        structure: '组织架构',
        employee: '员工管理',
        role: '角色管理',
        messages: '我的消息',
        setting: '企业设置',
        others: 'AI素材',
        goods: 'AI数据',
        dataTemplates: 'AI模板',
        tags: '智能价签',
    },
    pages: {
        
    },
};