import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ViewUI from 'view-design';
// import en from 'view-design/dist/locale/en-US';
import cn from 'view-design/dist/locale/zh-CN';
// import tw from 'view-design/dist/locale/zh-TW';
// import ja from 'view-design/dist/locale/ja-JP';
import { exCN } from './cn'
// import {exTW} from './tw'
// import {exEN} from './en'
// import {exJA} from './ja'

Vue.use(VueI18n);
Vue.use(ViewUI, { locale: cn })

const i18n = new VueI18n({
    locale: 'cn',
    messages:{
        cn:Object.assign(exCN),
        // en:Object.assign(exEN),
        // ja:Object.assign(exJA,ja),
        // tw:Object.assign(exTW,tw),
    }
});


export default i18n;
