import axios from 'axios'
import store from '../store';
import { Tip } from '@/components/err.js'
import ViewUI from 'view-design';

axios.defaults.baseURL='/cym/';

axios.create({
    timeout: 5000
});

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = token
        }
        //解决mock获取不到参数问题
        if (config.params != undefined) {
            config.data = config.params
        }
        return config
    },
    error => {
        return Promise.reject(error)
    });

axios.interceptors.response.use(
    res=>{
        return res
    },
    error=>{
        if(!(error.response)) { //判断服务器无响应
            ViewUI.Message.error('Empty response')
            return
        } 
        if(error.response.status===401){
            window.location.href = '#/login'
            store.dispatch('Logout')
            return false;
        } else if(error.response.status===504) {
            ViewUI.Message.error('Timeout')
        } else {
            if (window.location.href.indexOf('/login') !== -1) {
                ViewUI.Message.error(error.response.data.message)
            } else {
                Tip({
                    modal: true,
                    content: error.response.data.message,
                    title: '错误提示',
                    confirmTxt: '确定'
                })
            }
            return Promise.reject(error.response)
        } 
    }
);

const api={
    get(url, params={}) {
        return new Promise((resolve, reject) => {
            axios.get(url, params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    post(url, params={}) {
        return new Promise((resolve, reject) => {
            axios.post(url, params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    put(url, params={}) {
        return new Promise((resolve, reject) => {
            axios.put(url, params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    delete(url, params={}) {
        return new Promise((resolve, reject) => {
            axios.delete(url, params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getWebsite() {
        return axios.get('website').then(({ data }) => {
            axios.defaults.baseURL = `${data.data.cloud_api}/cym/`
            store.commit('SET_CLOUD_API', data.data.cloud_api)
            store.commit('SET_TAG_SUPPORT', data.data.tag_support)
        }).catch(() => {})
    }
}

export default api;